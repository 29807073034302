import React, { useState } from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const DateTimeField = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleChange = (date) => {
    console.log('date', date);
  }

  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column w-100 mb-3">
        <label>Start Date</label>
        <DatePicker
          name="start_date"
          selected={startDate}
          onChange={handleChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          placeholderText="Click to select the start date" />
      </div>
      <div className="d-flex flex-column w-100 mb-3">
        <label>End Date</label>
        <DatePicker
          name="end_date"
          selected={endDate}
          onChange={handleChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          placeholderText="Click to select the end date" />
      </div>
    </div>
  )
}

export default DateTimeField;