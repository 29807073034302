import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SectionStyled = styled.div`
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: #000000;
    margin-bottom:24px;
  }
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    letter-spacing: -1.5px;
    text-align: center;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
  }
`

const CardStyled = styled.div`
  margin-bottom: 50px;
  padding: 32px;
  background: #FFF9F6;
  border-radius: 16px;
  border: 1px solid #FFD8C6;
  border-radius: 16px;
`

const HowItWorks = () =>
  <div className="container mb-5">
    <SectionStyled>
      <h3 className="mb-2 text-center">How It Works</h3>
      <p className="mb-5 text-center">Business owners are facing an unprecedented challenge. Let’s make sure they get through this—together. Use the directory to easily browse businesses who could use your help, in cities across Canada.</p>
      <div className="row">
        <div className="col-lg-6 text-center">
          <CardStyled className="card">
            <div className="card-body">
              <h5>Support Businesses</h5>
              <p>Buy gift cards online now, use it later, or order products and services to help ensure a local business (or two) makes it through the pandemic.</p>
              <Link className="btn btn-outline-primary btn-block" to="/cities">Browse Businesses</Link>
            </div>
          </CardStyled>
        </div>
        <div className="col-lg-6 text-center">
          <CardStyled className="card">
            <div className="card-body">
              <h5>Add a Business</h5>
              <p>If you are a business owner, add your business to the directory for free and help make it easy for customers to support you during this difficult time.</p>
              <Link className="btn btn-outline-primary btn-block" to="/add">Add a Business</Link>
            </div>
          </CardStyled>
        </div>

      </div>
    </SectionStyled>
  </div>

export default HowItWorks;