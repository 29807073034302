import React from 'react';
import ProfileCard from '../utilities/ProfileCard';

const itemList = [{ id: 1, name: 'abcd' }, { id: 2, name: 'gfhj' }, { id: 3, name: 'fh' }, { id: 4, name: 'zxbv' }, { id: 5, name: 'ewyur' }, { id: 6, name: 'gsdjhbndf' }, { id: 7, name: 'gbhfvd' }, { id: 8, name: 'wgtaqe' }, { id: 1, name: 'ab' }, { id: 1, name: 'bcd' }, { id: 1, name: 'cde' }];

const Alphabet = ({
  data,
  artist,
  active,
  moreLess,
  toggleClass
}) => {
  const [searchInput, setSearchInput] = React.useState("");
  const [alphabet, setAlphabet] = React.useState("");

  const onSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }

  const onAlphabetClick = (e) => {
    setAlphabet(e.target.value)
  }

  const prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button type="button" className="btn btn-light" key={i} onClick={onAlphabetClick} value={String.fromCharCode(i)} >{String.fromCharCode(i)}</button>
      )
    }
    return result;
  }

  const elementContainsSearchString = (searchInput, element) => (searchInput ? element.fields.name.toLowerCase().includes(searchInput.toLowerCase()) : false);

  const filterItems = (data) => {
    let result = [];
    if (data && (searchInput || alphabet)) {
      result = data.filter((element) => (element.fields.name.charAt(0).toLowerCase() === alphabet.toLowerCase()) || elementContainsSearchString(searchInput, element));
    } else {
      result = data || [];
    }

    if (result.length !== 0) {

      result = (result || []).map(artist =>
        <ProfileCard
          key={artist.id}
          data={artist}
          active={active}
          moreLess={moreLess}
          toggleClass={toggleClass} />
      )
    } else {
      result = <div className="d-flex flex-column align-items-center justify-content-center">
        <p>No Matches found for names starting with:</p>
        <h5 className="mb-3">{alphabet}</h5>
        <button className="btn btn-secondary" onClick={() => {
          setAlphabet("");
          setSearchInput("");
        }}>Reset</button>
      </div>
    }
    return result;
  }

  // const itemList = undefined;
  const filteredList = filterItems(data);
  return (
    <div>
      <div className="form">
        <div className="form-group">
          <input type="search" placeholder="Search by name" className="form-control" onChange={onSearchInputChange} />
        </div>
        <div className="d-flex flex-wrap align-items-start btn-group mb-3" role="group">
          {prepareAlphabets()}
        </div>
        {filteredList}
      </div>
    </div>
  );
}

export default Alphabet;