import React from 'react'
import { Helmet } from 'react-helmet';

const OpenGraph = () => {
  return (
    <Helmet>
      <meta property="og:url" content="https://www.backinglocals.ca" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Backing Locals: a free directory supporting businesses, artists, makers, charitable organizations in your community" />
      <meta property="og:description" content="Backing Locals is a free digital directory designed to not only help Canadian business owners, but also charitable organizations, artists and other makers bring attention to their products and services, and generate business or raise money, during what’s likely the biggest challenge many have ever faced—the COVID-19 pandemic. Let’s come together as a community and make sure they get through this—together. Use the directories to easily browse businesses, charities/non-profits, artists and other creators who could use your help, in cities across Canada. Created by Oscar Hoang (Hustlebox) + Linda Hoang (Linda Hoang Digital)." />
      <meta property="og:image" content="https://linda-hoang.com/wp-content/uploads/2020/04/Backing-Locals-Community-Business-Charity-Nonprofit-Artist-Maker-Directory-3.jpg" />
    </Helmet>
  )
}

export default OpenGraph