import React from 'react';

import '../../css/LoadingSpinner.css';

const LoadingSpinner = props => {
  return (

    <div className="d-flex align-items-center justify-content-center" style={{ height: "350px" }}>
      <div className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </div>

  );
};

export default LoadingSpinner;
