import React from 'react'

const InputField = ({
  required,
  disabled = false,
  placeholder,
  label,
  type,
  name,
  value,
  error,
  touched,
  handleBlur,
  handleChange
}) =>
  <div className="form-group">
    <label className="w-100 clearfix">{label} <span className="font-weight-normal text-muted float-right">{required && "(Required)"}</span></label>
    <input
      disabled={disabled}
      type={type}
      name={name}
      className={`form-control ${touched.filter(item => item.includes(name)).length > 0 && error && "invalid"}`}
      placeholder={placeholder}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange} />
    {name === "tags" && <small>Example: food, pizza, italian - separate tags with comma</small>}
    {touched.filter(item => item.includes(name)).length > 0 && error ? <small className="text-danger">{error}</small> : <></>}
  </div>

export default InputField;