import React from 'react'

const TextareaField = ({ required, placeholder, label, name, value, error, touched, handleBlur, handleChange }) =>
  <div className="form-group">
    <label className="w-100 clearfix">{label} <span className="font-weight-normal text-muted float-right">{required && "(Required)"}</span></label>
    <textarea
      type="text"
      name={name}
      className={`form-control ${touched.filter(item => item.includes(name)).length > 0 && error && "invalid"}`}
      placeholder={placeholder}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange} />
    {touched.filter(item => item.includes(name)).length > 0 && error ? <small className="text-danger">{error}</small> : <></>}
  </div>

export default TextareaField;