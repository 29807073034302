import React from 'react';

import Footer from './Footer';
import Cities from './city/Cities';
import Page from './marketing/';
import CTA from './marketing/CTA';

const Main = () =>
  <>
    <Page />
    <Cities />
    <CTA />
    <Footer />
  </>

export default Main;