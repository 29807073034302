import isEmpty from './isEmpty';

const provinces = [
  { name: 'Alberta', abbreviation: 'AB' },
  { name: 'British Columbia', abbreviation: 'BC' },
  { name: 'Manitoba', abbreviation: 'MB' },
  { name: 'New Brunswick', abbreviation: 'NB' },
  { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
  { name: 'Northwest Territories', abbreviation: 'NT' },
  { name: 'Nova Scotia', abbreviation: 'NS' },
  { name: 'Nunavut', abbreviation: 'NU' },
  { name: 'Ontario', abbreviation: 'ON' },
  { name: 'Prince Edward Island', abbreviation: 'PE' },
  { name: 'Quebec', abbreviation: 'QC' },
  { name: 'Saskatchewan', abbreviation: 'SK' },
  { name: 'Yukon Territory', abbreviation: 'YT'
}];

const validateFields = (fields) => {
  let errors = {};
  let isValid;
  let fieldsIsValid = {};

  fields.name = !isEmpty(fields.name) ? fields.name : '';
  fields.address = !isEmpty(fields.address) ? fields.address : '';
  fields.city = !isEmpty(fields.city) ? fields.city.toString() : '';
  fields.province = !isEmpty(fields.province) ? fields.province.toString() : '';
  fields.email = !isEmpty(fields.email) ? fields.email : '';
  fields.desc = !isEmpty(fields.desc) ? fields.desc : '';
  fields.tags = !isEmpty(fields.tags) ? fields.tags : '';
  fields.type = !isEmpty(fields.type) ? fields.type : '';
  fields.category = !isEmpty(fields.category) ? fields.category : '';
  fields.website = !isEmpty(fields.website) ? fields.website : '';
  fields.donate = !isEmpty(fields.donate) ? fields.donate : '';
  fields.gift_card = !isEmpty(fields.gift_card) ? fields.gift_card : '';

  fields.facebook = !isEmpty(fields.facebook) ? fields.facebook : '';
  fields.twitter = !isEmpty(fields.twitter) ? fields.twitter : '';
  fields.instagram = !isEmpty(fields.instagram) ? fields.instagram : '';

  // business, artist, charity, name
  isValid = fields.name.length >= 3 ? true : false;
  errors.name = isValid ? "" : "Name is too short";
  fieldsIsValid = {
    ...fieldsIsValid,
    name: isValid
  }

  // address
  isValid = fields.address.length >= 3 ? true : false;
  errors.address = isValid ? "" : "Address is too short";
  fieldsIsValid = {
    ...fieldsIsValid,
    address: isValid
  }

  // city
  isValid = fields.city.length > 3 ? true : false;
  errors.city = isValid ? "" : "City name is too short";
  fieldsIsValid = {
    ...fieldsIsValid,
    city: isValid
  }

  // province
  const filtered = provinces.filter(p => p.name === fields.province);
  isValid = filtered.length > 0 ? true : false;
  errors.province = isValid ? "" : "Province doesn't exist";
  fieldsIsValid = {
    ...fieldsIsValid,
    province: isValid
  }

  // email is valid + required
  isValid = fields.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
  errors.email = isValid ? "" : "A valid email address is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    email: isValid
  }

  // desc
  isValid = fields.desc.length === 0 || fields.desc.length >= 3 ? true : false;
  errors.desc = isValid ? "" : "Please add some details about your listing.";
  fieldsIsValid = {
    ...fieldsIsValid,
    desc: isValid
  }

  // tags
  isValid = fields.facebook.length === 0 || !isEmpty(fields.tags) ? true : false;
  errors.tags = isValid ? "" : "Please add some tags.";
  fieldsIsValid = {
    ...fieldsIsValid,
    tags: isValid
  }

  // type: Artist, Business, Charity?
  isValid = !isEmpty(fields.type) ? true : false;
  errors.type = isValid ? "" : "Please select a type.";
  fieldsIsValid = {
    ...fieldsIsValid,
    type: isValid
  }

  // category
  isValid = !isEmpty(fields.category) ? true : false;
  errors.category = isValid ? "" : "Please select a category.";
  fieldsIsValid = {
    ...fieldsIsValid,
    category: isValid
  }

  // Website
  isValid = fields.website.length > 0 && fields.website.match(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g) ? true : false;  
  errors.website = isValid ? "" : "A valid website url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    website: isValid
  }

  // donate
  isValid = fields.donate.length === 0 || fields.donate.length > 0 && fields.donate.length > 0 && fields.donate.match(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g) ? true : false;
  errors.donate = isValid ? "" : "A valid website donation url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    donate: isValid
  }

  // gift_card
  isValid = fields.gift_card.length === 0 || fields.gift_card.length > 0 && fields.gift_card.match(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g) ? true : false;
  errors.gift_card = isValid ? "" : "A valid gift card url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    gift_card: isValid
  }

  // facebook
  isValid = fields.facebook.length === 0 || fields.facebook.length > 0 && fields.facebook.match(/(.*\.)?facebook\.com\/[A-z0-9_]+\/?/g) ? true : false;
  errors.facebook = isValid ? "" : "A valid facebook url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    facebook: isValid
  }

  // twitter
  isValid = fields.twitter.length === 0 || fields.twitter.length > 0 && fields.twitter.match(/(.*\.)?twitter\.com\/[A-z0-9_]+\/?/g) ? true : false;
  errors.twitter = isValid ? "" : "A valid twitter url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    twitter: isValid
  }

  // instagram
  isValid = fields.instagram.length === 0 || fields.instagram.length > 0 && fields.instagram.match(/(.*\.)?instagram\.com\/[A-z0-9_]+\/?/g) ? true : false;
  errors.instagram = isValid ? "" : "A valid instagram url is required";
  fieldsIsValid = {
    ...fieldsIsValid,
    instagram: isValid
  }


  return {
    errors,
    fieldsIsValid: fieldsIsValid
  }
};

export default validateFields;