import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from './styled/Media';

const FooterStyled = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0 50vh 0 50vh #FFFFFF;

  ${media.handheld`
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    justify-items: center;
    padding: 50px 0 0 0;
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 110px;
    
  `}
  & > div {
    width: 100%;
    ${media.handheld`
      margin-right: 24px;
      margin-left: 24px;
    `}
  }
`

const Footer = () =>
  <div className="container">
    <FooterStyled className="pt-4 my-5">
      <div>
        <div className="d-flex align-items-center mb-3">
          <img className="mr-2" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="24" height="24" />
          <p className="d-block lead text-muted m-0">Backing Locals.</p>
        </div>
        <p className="lead m-0">Made with ♥︎ in AB, CA</p>
        <small className="d-block text-muted mb-1">Hustlebox + Linda Hoang Digital, Inc.© 2020 All Rights Reserved.</small>
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-6 p-0">
          <h5>About</h5>
          <ul className="list-unstyled text-small">
            <li className="mb-1"><Link className="text-muted" to="/about">About</Link></li>
            <li className="mb-1"><Link className="text-muted" to="/privacy">Privacy</Link></li>
            <li className="mb-1"><Link className="text-muted" to="/tos">Terms</Link></li>
          </ul>
        </div>
        <div className="col-sm-12 col-lg-6 p-0">
          <h5>Resources</h5>
          <ul className="list-unstyled text-small">
            <li className="mb-1"><Link className="text-muted" to="/cities">Browse Businesses/Charities</Link></li>
            <li className="mb-1"><Link className="text-muted" to="/artists">Browse Artists and Creators</Link></li>
            <li className="mb-1"><Link className="text-muted" to="/add">Add to the Directory</Link></li>
          </ul>
        </div>
      </div>
    </FooterStyled>
  </div>
export default Footer;