import React, { useState, useRef, useEffect } from 'react'
import MapGL, { Marker, Popup, WebMercatorViewport } from "react-map-gl";
import styled from 'styled-components';
import isEmpty from '../form/validation/isEmpty';

import "mapbox-gl/dist/mapbox-gl.css"

const token = process.env.REACT_APP_MAPBOX_PUBLIC_KEY;

const PopupStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  h3 {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4D5255;
  }
  p, a {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #385075;
    letter-spacing: 0;
    line-height: 24px;
  }
`
const PointerStyled = styled.div`
  height: 16px;
  width: 16px;
  background: #333333;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  background: ${props => props.isSelected ? "#8048f3" : "#333333"};
  box-shadow: inset 0 4px rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* animation: pulse 2.75s infinite; */
  z-index: 99999;
`

const SearchableMap = ({ city, selected }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [current, setCurrent] = useState({});
  const [viewport, setViewport] = useState({});
  const [settings, setSettings] = useState({
    dragPan: true,
    dragRotate: true,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: false,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 0,
    maxZoom: 18,
    minPitch: 0,
    maxPitch: 85
  }
  );

  useEffect(() => {
    let array = city.map(local => {
      return [+local.fields.lng, +local.fields.lat]
    });

    const test = new WebMercatorViewport({ width: 800, height: 600 })
      .fitBounds(array.length > 1 ? array : [array[0], array[0]], {
        padding: 20,
        offset: [0, -100]
      });
    setViewport(test)
  }, [])

  useEffect(() => {
    setCurrent(selected);
  }, [selected])

  let mapRef = useRef(null);

  const handleViewportChange = viewport => {
    setViewport(prevState => {
      return {
        ...prevState,
        ...viewport
      }
    })
  }

  const _renderMarker = (places, current) => {
    return places.map(place =>
      <Marker
        key={place.fields.name}
        latitude={+place.fields.lat || 0}
        longitude={+place.fields.lng || 0}
        captureDrag={false}
        captureDoubleClick={false}>
        <PointerStyled
          isSelected={place.fields.name === current.name ? true : false}
          onMouseEnter={() => {
            setCurrent(place.fields);
            setShowPopup(true);
          }}
        />
      </Marker>
    )
  }

  const _renderPopup = (current) => {
    if (!isEmpty(current) && showPopup) {
      return (
        <Popup
          latitude={+current.lat}
          longitude={+current.lng}
          closeButton={true}
          closeOnClick={false}
          onClose={() => setShowPopup(false)}
          anchor="bottom">
          <PopupStyled>
            <h3>{current.name}</h3>
            <p className="mb-3">{current.address} <span className="badge badge-warning ml-2">{current.category}</span></p>
            <a
              role="button"
              className="btn btn-outline-primary btn-sm btn-block"
              href={current.website.match(/^(http|https):/g) ? `${current.website}?ref=backinglocals` : `http://${current.website}?ref=backinglocals`}
              target="_blank"
              rel="noopener">Go to Website</a>
          </PopupStyled>
        </Popup>
      )
    }
  }

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      {...settings}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onViewportChange={handleViewportChange}
      mapboxApiAccessToken={token}>
      {_renderMarker(city, current)}
      {_renderPopup(current)}
    </MapGL>
  )
}

export default SearchableMap;