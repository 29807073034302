import React from 'react';
import styled from 'styled-components';

import Header from '../Header'
import Footer from '../Footer';

import { media } from '../styled/Media';

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 24px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-6 p-0 mx-auto">
            <SectionStyled>
              <h5>About Backing Locals</h5>
              
              <p>Backing Locals is a digital directory designed to not only help Canadian business owners, but also charitable organizations, artists and other makers bring attention to their products and services, generate business or raise money, during what’s likely the biggest challenge many have ever faced—the COVID-19 pandemic. Let’s come together as a community and make sure they get through this—together.</p>
              <p>Use the directories to easily browse businesses, charities/non-profits, artists and other creators who could use your help, in cities across Canada.</p>
              <p>These listings are meant to make it easier for people who want to find services and support organizations and people in their cities.</p>
              <p>The illustrations on this website are from <a href="https://www.openpeeps.com/?ref=backinglocals" target="_blank" rel="noopener">Open Peeps</a>, an open-source hand-drawn illustration library by Pablo Stanley, used under CCO License.</p>
              <h5>About the listings</h5>
              <p>The initial businesses, charities, non-profits, artists and makers included in the directory at launch were added based on existing, publicly available information about each organization or individual creator online.</p>
              <p>Any business, charity, non-profit, artist or maker in Canada is able to add themselves to the directory using the website form. Please review the directory to see if there is already a listing before you submit.</p>
              <p>If information in an existing listing is outdated, incorrect, or needs to be removed, you can request a change at any time by emailing <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a>.</p>
              <p>If you would like to list your business, charity, non-profit, or self (as an artist or maker) on this directory, you agree that you are following any COVID-19-related government rules or regulations within your jurisdiction, and are taking precautions or have made changes to your operations to ensure the safety of your customers.</p>
              <ul>
                <li>
                  <a
                    role="button"
                    className="btn btn-link"
                    href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html"
                    target="_blank"
                    rel="noopener">See Government of Canada updates on COVID-19</a>
                </li>
                <li>
                  <a
                    role="button"
                    className="btn btn-link"
                    href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"
                    target="_blank"
                    rel="noopener">See World Health Organization updates on COVID-19</a>
                </li>
              </ul>
              <h5>Who created Backing Locals</h5>

              <p>Backing Locals was created in Edmonton, Alberta by Oscar Hoang of <a href="https://www.hustlebox.ca/?ref=backinglocals" target="_blank" rel="noopener">Hustlebox Design,</a> and Linda Hoang of <a href="http://linda-hoang.com/?ref=backinglocals" target="_blank" rel="noopener">Linda Hoang Digital</a>—two entrepreneurial siblings with their own individual businesses, who are also part of a family that owns a local restaurant struggling to survive this pandemic. They know firsthand the challenge and uncertainty local business owners are facing, and wanted to create a helpful resource during this difficult time, to only help business owners, but also draw attention to and help people more easily connect with and support charities, non-profits, artists and creators.</p>
              <h5>Support Backing Locals </h5>
              <p>Backing Locals is not a revenue-generating project. Hustlebox and Linda Hoang Digital have donated their own time and resources to create this directory and service for Canadians and local communities, for free. If you are interested in helping cover costs associated with promoting and maintaining this directory, please email <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a> for sponsorship inquiries.</p>
            </SectionStyled>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About;