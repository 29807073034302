import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';

import DateTimeField from './fields/DateTimeField';
import LocationFields from './fields/LocationFields';
import CommonFields from './fields/CommonFields';
import InternetFields from './fields/InternetFields';

import { toTitleCase } from '../../helpers';
import { media } from '../styled/Media';

const PageStyled = styled.div`
  margin: 30px auto 100px auto;
  width: 720px;
  ${media.handheld`
    width: 100%;
    padding: 0 24px;
  `}

  h1 {
    margin-bottom: 24px;
    font-family: 'Open Sans', sans-serif;
    font-size: 54px;
    line-height: 60px;
    color: #000000;
    letter-spacing: -3px;
    text-align: center;
  ${media.handheld`
    font-size: 40px;
    line-height: 50px;
  `}
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #4D5255;
    letter-spacing: 0;
    text-align: center;
  }
`

const AddVirtual = () => {
  const [formValues, setFormValues] = useState({});

  let history = useHistory();
  let location = useLocation();

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  const handleSubmit = async (e) => {
    formValues.type = toTitleCase(location.pathname.split("/")[2]);

    e.preventDefault();
    await fetch(`${process.env.NODE_ENV === 'production' ? `https://salty-basin-83556.herokuapp.com/api/artist/add` : `/api/artist/add`}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    });
    history.push("/thank-you");
  }

  return (
    <div className="container py-5">
      <PageStyled className="col-sm-6 text-center">
        <h1>Add a Virtual Class.</h1>
        <p>If you are a business hosting a virtual class; let people can support you online, fill out the form below.</p>
      </PageStyled>
      <div className="card col-sm-6 p-0 mx-auto">
        <form onSubmit={handleSubmit} className="form">
          <div className="card-body mb-5">
       
            <DateTimeField
              formValues={formValues}
              handleChange={handleChange} />
            <LocationFields
              formValues={formValues}
              handleChange={handleChange} />

            <CommonFields
              formValues={formValues}
              handleChange={handleChange} />

            {/* <InternetFields
              formValues={formValues}
              handleChange={handleChange} /> */}

          </div>
          <div className="card-footer">
            <div className="d-flex flex-wrap justify-content-between">
              <button className="btn btn-secondary" onClick={() => history.goBack()}>Cancel</button>
              <button className="btn btn-primary">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddVirtual;