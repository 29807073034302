import React from 'react';
import styled from 'styled-components';

import Header from '../Header'
import Footer from '../Footer';

import { media } from '../styled/Media';

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 24px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const Tos = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-6 p-0 mx-auto">
            <SectionStyled>
              <h5>TERMS OF USE</h5>
              <h5>OVERVIEW</h5>
              <p>Backing Locals is a digital directory designed to not only help Canadian business owners, but also charitable organizations, artists and other makers bring attention to their products and services, generate business or raise money, during what’s likely the biggest challenge many have ever faced—the COVID-19 pandemic. Let’s come together as a community and make sure they get through this—together. Visitors to the site should use the directories to easily browse businesses, charities/non-profits, artists and other creators who could use your help, in cities across Canada. These listings are meant to make it easier for people who want to find services and support organizations and people in their cities. Any business, charity, non-profit, artist or maker in Canada is able to add themselves to the directory using the website form. Please review the directory to see if there is already a listing before you submit. If information in an existing listing is outdated or incorrect, you can request a change by emailing <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com.</a> If you would like to list your business, charity, non-profit, or self (as an artist or maker) on this directory, you agree that you are following any COVID-19-related government rules or regulations within your jurisdiction, and are taking precautions or have made changes to your operations to ensure the safety of your customers.</p>

              <p>This website is operated as Backing Locals created by Hustlebox and Linda Hoang Digital. Throughout the site, the terms “we”, “us” and “our” refer to Backing Locals. Hustlebox and Linda Hoang Digital offer this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>

              <p>By visiting our site and/ or submitting a listing, you engage in our “Use” and agree to be bound by the following terms and conditions (“Terms of Use”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Use apply  to all users of the site, including without limitation users who are browsers or contributors of content.</p>

              <p>Please read these Terms of Use carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Use. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Use are considered an offer, acceptance is expressly limited to these Terms of Use.</p>

              <p>Any new features or tools which are added to the current website shall also be subject to the Terms of Use. You can review the most current version of the Terms of Use at any time on this page. We reserve the right to update, change or replace any part of these Terms of Use by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>

              <h5>GENERAL CONDITIONS</h5>

              <p>You may not use any information on this website for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>

              <p>You understand that content submitted may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. </p>

              <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the website. </p>

              <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>

              <h5>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h5>

              <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>

              <h5>SITE MODIFICATIONS </h5>

              <p>We reserve the right at any time to modify or discontinue the features on this website (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, suspension or discontinuance of the services on this site. </p>

              <h5>LISTINGS / SUBMISSIONS</h5>

              <p>You agree to provide current, complete and accurate listing account information if you are submitting a listing to the website. You agree that by submitting a listing, you are also acknowledging that the business, non-profit, charity, organization, artist or creator submitted is following any COVID-19-related government rules or regulations within your jurisdiction, and are taking precautions or have made changes to operations to ensure the safety of customers.</p>

              <p>You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties in your listing submission. We take no responsibility and assume no liability for any inaccurate listings or information submitted by you or any third-party.</p>

              <p>We may, but have no obligation to, monitor, edit or remove content or listings that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Use.</p>

              <h5>OPTIONAL TOOLS</h5>

              <p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>

              <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>

              <p>We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</p>

              <h5>THIRD-PARTY LINKS</h5>

              <p>Certain content on this website may include materials from third-parties.</p>

              <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>

              <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transactions. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>

              <h5>PERSONAL INFORMATION</h5>

              <p>Your submission of personal information through the store is governed by our Privacy Policy.</p>

              <h5>ERRORS, INACCURACIES AND OMISSIONS</h5>

              <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to listing descriptions. We reserve the right to correct any errors, inaccuracies or omissions, and to change, update or cancel information or submissions or on any related website is inaccurate at any time without prior notice (including after you have submitted your listing.</p>

              <p>We undertake no obligation to update, amend or clarify information on the website or on any related website, including without limitation except as required by law. No specified update or refresh date applied on the website or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>

              <h5>PROHIBITED USES</h5>

              <p>In addition to other prohibitions as set forth in the Terms of Use, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>

              <h5>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h5>

              <p>We do not guarantee, represent or warrant that your use of this website will be uninterrupted, timely, secure or error-free.</p>

              <p>We do not warrant that the results that may be obtained from the use of this website will be accurate or reliable.</p>

              <p>You agree that from time to time we may remove the website for indefinite periods of time or cancel the website at any time, without notice to you.</p>

              <p>You expressly agree that your use of, or inability to use, the website is at your sole risk. The website and all services delivered to you through the website are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>

              <p>In no case shall Backing Locals, Hustlebox, Linda Hoang Digital, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>

              <h5>INDEMNIFICATION</h5>

              <p>You agree to indemnify, defend and hold harmless Backing Locals, HustleBox, Linda Hoang Digital and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Use or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>

              <h5>SEVERABILITY</h5>

              <p>In the event that any provision of these Terms of Use is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>

              <h5>TERMINATION</h5>

              <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>

              <p>These Terms of Use are effective unless and until terminated by either you or us. You may terminate these Terms of Use at any time by notifying us that you no longer wish to use the website or cease using the website. </p>

              <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Use, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our website (or any part thereof).</p>


              <h5>ENTIRE AGREEMENT</h5>

              <p>The failure of us to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.</p>

              <p>These Terms of Use and any policies or operating rules posted by us on this site or in respect to the website constitutes the entire agreement and understanding between you and us and govern your use of the website, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use).</p>

              <p>Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.</p>

              <h5>GOVERNING LAW</h5>

              <p>These Terms of Use and any separate agreements whereby we provide you access and use of the website shall be governed by and construed in accordance with Canadian law. </p>

              <h5>CHANGES TO TERMS OF SERVICE</h5>

              <p>You can review the most current version of the Terms of Use at any time at this page.</p>

              <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Use by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website following the posting of any changes to these Terms of Use constitutes acceptance of those changes.</p>

              <h5>CONTACT INFORMATION</h5>

              <p>Questions about the Terms of Use should be sent to us at <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com.</a></p>
            </SectionStyled>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Tos;