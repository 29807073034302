import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Header from '../Header';
import Footer from '../Footer';
import CityList from '../city/CityList';
import LoadingSpinner from '../utilities/LoadingSpinner';

const HeaderStyled = styled.h3`
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #000000;
  letter-spacing: -1.5px;
  text-align: center;
`

const Cities = () => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let location = useLocation();
  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);

      try {
        let res = await fetch(`${process.env.NODE_ENV === 'production' ? `https://salty-basin-83556.herokuapp.com/api/city/all` : '/api/city/all'}`);
        let json = await res.json();
        setCities(json);
      } catch (err) {
        throw err;
      }
      setIsLoading(false);
      window.scrollTo(0, 0);
    };
    runEffect();
  }, []);

  if (cities.length === 0 || isLoading) return <LoadingSpinner />
  return (
    <>
      {location.pathname.includes("cities") && <Header className="mb-5" />}
      <div className="container p-0">
        <div className="my-5 px-3">
          <HeaderStyled>Support businesses, charities and non-profits in Canada</HeaderStyled>
          {location.pathname.includes("cities") && <p className="mt-3 text-center"><small>Don’t see your city? Submit a business, charity, or non-profit in a Canadian city to help grow the directory. Currently only businesses, charities or non-profits with physical addresses / storefronts can be added to this directory. If you have a business without a public storefront, submit as an artist/creator/maker.</small></p>}
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between" style={{ marginBottom: "120px" }}>
          {(cities || []).map(city => {
            if (city.fields.business_total > 0) return <CityList city={city} />
          })}
        </div>
      </div>
      {location.pathname.includes("cities") && <Footer />}
    </>
  )
}

export default Cities;