import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FiChevronUp, FiMenu } from 'react-icons/fi';
import { media } from './styled/Media';

const NavStyled = styled.nav`
  box-shadow: 0 2px 16px 0 rgba(197,197,197,0.50);
  border-bottom: 1px solid #E8E8E8;
  background: #FFFFFF;
  padding: 16px;
  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 250px;
    align-items: center;
    margin: 0 auto;
    ${media.handheld`
      grid-template-columns: 1fr;
    `}
    & > div {
      ${media.handheld`
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        margin-bottom: 16px;     
      `}
    }
  }
  &.hideOnMobile {
    display: flex!important;
    ${media.handheld`
      display: none!important;
    `}
  }
`
const NavMobileStyled = styled.nav`
  box-shadow: 0 2px 16px 0 rgba(197,197,197,0.50);
  border-bottom: 1px solid #E8E8E8;
  background: #FFFFFF;
  padding: 16px;
  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 48px;
    align-items: center;
    margin: 0 auto;
    & > div {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
      margin-top: 16px;     
    }
  }
  &.hideOnMobile {
    display: flex!important;
    ${media.handheld`
      display: none!important;
    `}
  }
  &.showOnMobile {
    display: none!important;
    ${media.handheld`
      display: flex!important;
    `}
  }
`

const LogoStyled = styled(Link)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
  letter-spacing: -1px;
  &:hover {
    color: #000000;
  }
`
const NavLinkStyled = styled(NavLink)`
  color: #333333;
  &:hover {
    color: #000000;
    text-decoration: none;
  }
  &.selected {
    color: #0091FF!important;
    background: rgba(22,142,234,0.1);
  }
`

const Header = (props) => {
  const [active, setActive] = React.useState(null);

  let location = useLocation();

  const toggleClass = () => {
    setActive(active === "dog" ? null : "dog")
  }

  const moreLess = (clicked) => {
    if (active === clicked) { return <FiChevronUp size="24px" /> }
    else { return <FiMenu size="24px" /> }
  }

  return (
    <>
      <NavStyled {...props} className="hideOnMobile">
        <div className={`d-flex flex-row align-items-center justify-content-between ${location.pathname.includes("city") ? "px-0" : "container p-0"}`}>
          <div className="d-flex align-items-center">
            <LogoStyled to="/" className="navbar-brand mr-4">Backing Local<span className="text-primary">s.</span></LogoStyled>
            <NavLinkStyled to="/about" activeClassName="selected px-3 py-2 rounded-5" className="mr-4">About</NavLinkStyled>
            <NavLinkStyled to="/artists" activeClassName="selected px-3 py-2 rounded-5" className="mr-4">Artists and Creators</NavLinkStyled>
          </div>
          <Link to="/cities" className="btn btn-light rounded-5" type="button">Browse Businesses/Charities</Link>
        </div>
      </NavStyled>

      <NavMobileStyled {...props} className="showOnMobile">
        <div className={`${location.pathname.includes("city") ? "px-0" : "container p-0"}`}>
          <LogoStyled to="/" className="navbar-brand mr-4">Backing Local<span className="text-primary">s.</span></LogoStyled>
          <button type="button" className="btn btn-light rounded-5 mx-auto py-2" onClick={() => toggleClass()}>
            {moreLess("dog")}
          </button>
          {active === "dog" && <div>
            <NavLinkStyled to="/about" className="mr-3">About</NavLinkStyled>
            <NavLinkStyled to="/artists" className="mr-3">Artists</NavLinkStyled>
            <Link to="/cities" className="btn btn-light rounded-5" type="button">Browse Businesses/Charities</Link>
          </div>}

        </div>
      </NavMobileStyled>
    </>
  )
}

export default Header;