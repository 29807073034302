import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import Geocoder from 'react-mapbox-gl-geocoder'
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css"

import LoadingSpinner from '../utilities/LoadingSpinner';

import SelectField from './fields/SelectField';
import LocationFields from './fields/LocationFields';
import CommonFields from './fields/CommonFields';
import InternetFields from './fields/InternetFields';

import validateFields from './validation/validateFields';
import validateForm from './validation/validateForm';

import { media } from '../styled/Media';

const token = process.env.REACT_APP_MAPBOX_PUBLIC_KEY;

const PageStyled = styled.div`
  margin: 30px auto 100px auto;
  width: 720px;
  ${media.handheld`
    width: 100%;
    padding: 0 24px;
  `}
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 54px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 60px;
    letter-spacing: -3px;
    margin-bottom: 24px;
    ${media.handheld`
      font-size: 40px;
      line-height: 50px;
    `}
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #4D5255;
    letter-spacing: 0;
    text-align: center;
  }
`

const AddLocal = () => {
  const [formValues, setFormValues] = useState({});
  const [viewport, setViewport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState([])
  const [fieldsIsValid, setFieldsIsValid] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);

  let history = useHistory();
  let location = useLocation(); // REMOVE might be using right now

  React.useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  React.useEffect(() => {
    if (formValues !== null) {
      const validatedFields = validateFields(formValues);
      const touchedErrors = Object.keys(validatedFields.errors)
        .filter(key => touched.includes(key)) // get all touched keys - expected output: ["name", "email", "age", "gender"]
        .reduce((acc, key) => {
          if (!acc[key]) {
            acc[key] = validatedFields.errors[key]
          }
          return acc;
        }, {});

      setErrors(touchedErrors);
      setFieldsIsValid(validatedFields.fieldsIsValid);
    }
  }, [touched, formValues]);

  React.useEffect(() => {
    const isValid = validateForm(fieldsIsValid);
    setFormIsValid(isValid);
  }, [fieldsIsValid]);

  const handleBlur = (e) => {
    // if touched hasn't been touched before add it to the array
    if (!touched.includes(e.target.name)) {
      setTouched([
        ...touched,
        e.target.name
      ])
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formIsValid === true) {
      setIsLoading(true);
      await fetch(`${process.env.NODE_ENV === 'production' ? `https://salty-basin-83556.herokuapp.com/api/local/add` : `/api/local/add`}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formValues),
      });

      setIsLoading(false);
      history.push("/thank-you");
    }
  }

  const onSelected = (viewport, item) => {
    const placePattern = /\b(\w*place\w*)\b/;
    const regionPattern = /\b(\w*region\w*)\b/;

    setViewport(viewport);
    setFormValues({
      ...formValues,
      name: item.text,
      address: item.properties.address,
      city: item.context.filter(place => placePattern.test(place.id)).map((obj) => {
        return obj.text;
      }),
      province: item.context.filter(place => regionPattern.test(place.id)).map((obj) => {
        return obj.text;
      }),
      lng: item.center[0],
      lat: item.center[1],
    })
  }
  const queryParams = { country: 'ca' };

  if (isLoading) return <LoadingSpinner />
  return (
    <div style={{ background: "#F9F9F9" }}>
      <div className="container py-5">
        <PageStyled className="col-sm-6 text-center">
          <h1>Add a Business, Charity or Non-Profit in your city.</h1>
          <p>Start typing your organization name below. If you are registered with a physical location through Google, the name will appear.</p>
          <small className="form-text text-muted">If your business, charity, or non-profit does not appear, please follow these steps to first register with Google <a href={`https://support.google.com/business/answer/6300717?hl=en`} target="_blank" rel="noopener">here</a>.</small>
        </PageStyled>

        <div className="card col-sm-6 p-0 mx-auto">
          <form onSubmit={handleSubmit} className="form" noValidate>
            <div className="card-body mb-5">
              <div className="form-group">
                <label>Locate by Name</label>
                <Geocoder
                  mapboxApiAccessToken={token}
                  onSelected={onSelected}
                  viewport={viewport}
                  minLength={4}
                  hideOnSelect={false}
                  queryParams={queryParams} />
                <small>Currently only businesses, charities or non-profits with physical addresses can be added to the directory.</small>
              </div>

              <LocationFields
                disabled={'lat' in formValues && 'lng' in formValues ? false : true}
                errors={errors}
                touched={touched}
                formValues={formValues}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <SelectField
                required
                options={["Business", "Charity and Non-Profit"]}
                label="Type"
                name="type"
                value={formValues.type}
                error={errors.type}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange} />


              <SelectField
                required
                options={[
                  "Arts and Entertainment",
                  "Beer and Liquor",
                  "Bicycle Repair",
                  "Business Services",
                  "Charities and Nonprofit",
                  "Commercial Services",
                  "Communications",
                  "Construction and Renovation",
                  "Design and Creative",
                  "Education",
                  "Finance and Accounting",
                  "Grocery",
                  "Health and Wellness",
                  "Health and Beauty",
                  "Home Repair and Maintenance",
                  "Hospitality",
                  "Insurance",
                  "Pets",
                  "Real Estate",
                  "Restaurant and Food",
                  "Retail",
                  "Rideshare, Taxi and Transportation",
                  "Sports and Fitness",
                  "Support Services",
                  "Technology",
                  "Toys and Hobbies",
                  "Vehicle Maintenance and Repair",
                  "Other"
                ]}
                label="Category"
                name="category"
                value={formValues.category}
                error={errors.category}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <CommonFields
                errors={errors}
                touched={touched}
                formValues={formValues}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <InternetFields
                errors={errors}
                touched={touched}
                formValues={formValues}
                handleBlur={handleBlur}
                handleChange={handleChange} />

            </div>
            <div className="card-footer">
              <div className="d-flex flex-wrap justify-content-between">
                <button className="btn btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                <button type="submit" className={`btn ${!formIsValid ? "disabled btn-secondary" : "btn-primary"}`} tabIndex="-1" role="button" aria-disabled="true" disabled={!formIsValid}>Submit</button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-sm-6 p-0 my-3 mx-auto text-center">
          <p><small className="text-muted">Having trouble? Email <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a> with your business details and we'll add to the directory in the next <span className="text-success">24hrs</span>.</small></p>
        </div>
      </div>
    </div>
  )
}

export default AddLocal;