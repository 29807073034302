import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '../styled/Media';

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 24px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const NotFound = () =>
  <div className="container my-5">
    <SectionStyled>
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-lg-6 col-sm-12 p-0">
          <h5 className="text-center">Opps something went wrong.</h5>
          <div className="d-flex flex-column">
            <Link to="/cities" className="btn btn-block btn-outline-primary">Back to Directory</Link>
            <Link to="/artists" className="btn btn-block btn-outline-primary">Browse Artists</Link>
          </div>
        </div>
      </div>
    </SectionStyled>
  </div>

export default NotFound;