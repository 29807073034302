import React from 'react';
import styled from 'styled-components';

import Header from '../Header'
import Footer from '../Footer';

import { media } from '../styled/Media';

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 24px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`
const Privacy= () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-6 p-0 mx-auto">
            <SectionStyled>
            <h5>BACKING LOCAL PRIVACY POLICY</h5>

            <p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://www.backinglocals.com (the “Site”).</p>

            <h5>PERSONAL INFORMATION WE COLLECT</h5>

            <p>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>

            <p>We collect Device Information using the following technologies:</p>
            <ul>

                <li>“Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.</li>
                <li>“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
                <li>“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.</li>
            </ul>

            <p>Additionally when you submit a listing through the Site, we collect certain information from you, including your name, city, email address, and other data you want included in the public listing. We refer to this information as “Listing Information.” When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Listing Information.</p>

            <h5>HOW DO WE USE YOUR PERSONAL INFORMATION?</h5>

            <p>We use the Listing Information that we collect to create a listing on the Site. Additionally, we may use this Order Information to communicate with you if there is an error in your listing or your listing needs to be updated. Your email address may also be added to a mailing list where you may receive communications from Hustlebox or Linda Hoang Digital. You may unsubscribe at any time.</p>

            <p>We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how visitors browse and interact with the Site, to use in or assess the success of any marketing and advertising campaigns). </p>

            <h5>SHARING YOUR PERSONAL INFORMATION</h5>

            <p>Your Personal Information may be shared with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our visitors use the Site. you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout. Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

            <h5>BEHAVIOURAL ADVERTISING</h5>

            <p>As described above, we may also use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.</p>

            <p>You can opt out of targeted advertising by:</p>
            <div>
              <small>[[</small><br/>
                <small className="mb-0">INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED.</small><br/>
                <small className="mb-0">COMMON LINKS INCLUDE:</small><br/>
                <small className="mb-0">FACEBOOK - https://www.facebook.com/settings/?tab=ads </small>
                <small className="mb-0">GOOGLE - https://www.google.com/settings/ads/anonymous </small>
                <small className="mb-0">BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads </small><br/>
              <small>]]</small>
            </div>
            <br/>
            <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/ </p>

            <h5>DO NOT TRACK</h5>
            <p>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

            <h5>DATA RETENTION</h5>
            <p>When you submit a listing through the Site, we will maintain your Listing Information for our records unless and until you ask us to delete this information.</p>

            <h5>CHANGES</h5>
            <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>

            <h5>CONTACT US</h5>
            <p>For more information about privacy practices, if you have questions, or if you would like to make a listing update or a complaint, please contact us by e-mail at <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a>.</p>
            </SectionStyled>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Privacy;