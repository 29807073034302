import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Header from './Header'
import Footer from './Footer';

import { media } from './styled/Media';

import SiblingImage from "./marketing/images/siblings-1.svg";

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 24px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 24px;
  }
`

const Thankyou = () =>
  <>
    <Header />
    <div className="container my-5">
      <SectionStyled>
        <div className="d-flex flex-row flex-wrap align-items-center">
          <div className="col-lg-6">
            <img className="img-fluid d-flex my-5 mx-auto" src={SiblingImage} alt="Generic placeholder image" />
          </div>
          <div className="col-lg-6">
            <h5>Thank you.</h5>
            <p>You’ve successfully submitted a listing to the directory.<br /><small> If you made an error in your submission, email <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a> to request an update.</small></p>
            <div className="d-flex">
              <Link to="/cities" className="btn btn-outline-primary mr-2">Back to Directory</Link>
              <Link to="/artists" className="btn btn-outline-primary ml-2">Browse Artists</Link>
            </div>
          </div>
        </div>
      </SectionStyled>
    </div>
    <Footer />
  </>

export default Thankyou;