import React from 'react'
import { Link } from "react-router-dom";
import styled from 'styled-components';

import Header from '../Header'
import Footer from '../Footer'

import { media } from '../styled/Media';

const PageStyled = styled.div`
  margin: 30px auto 100px auto;
  width: 720px;
  ${media.handheld`
    width: 100%;
    padding: 0 24px;
  `}
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 54px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: 60px;
    letter-spacing: -3px;
    margin-bottom: 24px;
    ${media.handheld`
      font-size: 40px;
      line-height: 50px;
    `}
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
  }
`

const CardStyled = styled.div`
  /* Rectangle: */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0 0 0 1px rgba(230,230,230,0.50), 0 5px 10px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  padding: 24px;
  min-height: 244px;
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 24px;
    color: #4D5255;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 80px;
    color: #136BF5;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const FormWizard = () => {
  let businessType = "business";
  let artistType = "artist";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  
  return (
    <div style={{ background: "#F9F9F9" }}>
      <Header />
      <div className="container py-5">
        <div style={{ background: "#F9F9F9" }}>
          <PageStyled className="col-sm-6 text-center">
            <h1>Are you a business, charity, non-profit, artist or creator?</h1>
            <p>Add yourself to the directory for free.</p>
          </PageStyled>

          <div className="d-flex flex-wrap justify-content-center">
   
            <div className="col-sm-12 col-lg-4 mb-4 text-center">
              <CardStyled>
                <h3>Add a Business</h3>
                <small className="mb-3">Business, Charity or Non-Profit.</small>
                <p>If you are a Business/Charity/Non-Profit with a website where people can support you online, add it to the directory.</p>
                <Link class="btn btn-primary btn-block rounded-5" to={`/add/${businessType}`}>Add</Link>
              </CardStyled>
            </div>
            <div className="col-sm-12 col-lg-4 mb-4 text-center">
              <CardStyled>
                <h3>Add an Artist</h3>
                <small className="mb-3">Freelancer, Creator or Maker</small>
                <p>If you are an artist, freelancer, creator or maker with a website where people can support you online, add it to the directory.</p>
                <Link class="btn btn-primary btn-block rounded-5" to={`/add/${artistType}`}>Add</Link>
              </CardStyled>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default FormWizard;