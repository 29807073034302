import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '../styled/Media';
import HeroImage from "./images/hero-1.svg";
import HeroImage2 from "./images/hero-2.svg";

const HeroStyled = styled.div`
  /* background: #FEFDF4; */
  padding: 80px 0 80px 0;
  margin-bottom: 50px;
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: #000000;
    margin-bottom:24px;
    ${media.handheld`
      font-size: 32px;
      line-height: 40px;
    `}
  }
  h5 {
    font-family: 'Roboto' sans-serif;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    line-height: 38px;
    margin-bottom: 50px;
    ${media.handheld`
      font-size: 18px;
      line-height: 30px;
    `}
  }
  .hideOnMobile {
    display: flex!important;
    ${media.handheld`
      display: none!important;
    `}
  }
  .showOnMobile {
    display: none!important;
    ${media.handheld`
      display: flex!important;
    `}
  }
`

const Hero = () =>
  <HeroStyled>
    <div class="container">
      <div className="row">
        <div className="col-lg-7 col-sm-12">
          <div className="d-flex flex-row">
            <h1>Support Local Businesses, Charities and Artists.</h1>
            <div className="showOnMobile col-lg-5">
              <img className="img-fluid d-flex mx-auto" src={HeroImage2} alt="Generic placeholder image" />
            </div>
          </div>
          <h5>Buy gift cards online now to use later, order products or services online or donate to help ensure your local community makes it through the pandemic.</h5>
          <div className="col-sm-7 p-0">
            <Link to="/cities" class="btn btn-primary btn-block rounded-5 py-3 px-5">Browse Businesses/Charities</Link>
          </div>
        </div>
        <div className="hideOnMobile col-lg-5">
          <img className="img-fluid d-flex mx-auto" src={HeroImage} alt="Generic placeholder image" />
        </div>
      </div>
    </div>
  </HeroStyled>

export default Hero;