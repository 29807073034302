import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../styled/Media';

const CardStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #d2d9e5;
  box-shadow: 0 1px 0 0 rgba(5,12,38,0.1);
  border-radius: 6px;
  ${media.handheld`
    grid-template-columns: 1fr 1fr;
  `}

  h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4D5255;
  }
  
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #385075;
  }
`

const CityList = ({ city }) =>
  <div key={city.id} className="col-md-4 mb-4">
    <CardStyled>
      <div>
        <h2 className="mb-0">{city.fields.name}</h2>
        <p className="mb-0">{city.fields.business_total} Local Listings</p>
      </div>
      <Link to={`/city/${city.fields.name}`} className="btn btn-outline-primary">Browse</Link>
    </CardStyled>
  </div>

export default CityList;