import React from 'react'

const SelectField = ({ required, options, label, name, value, error, touched, handleBlur, handleChange }) =>
  <div className="form-group">
    <label className="w-100 clearfix">{label} <span className="font-weight-normal text-muted float-right">{required && "(Required)"}</span></label>
    <select
      type="text"
      className={`select-css form-control ${touched.filter(item => item.includes(name)).length > 0 && error && "invalid"}`}
      name={name}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}>
      <option value="" defaultValue="">Select the {name}</option>
      {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
    </select>
    {touched.filter(item => item.includes(name)).length > 0 && error ? <small className="text-danger">{error}</small> : <></>}
  </div>

export default SelectField;