import React from 'react'
import InputField from './InputField';


const InternetFields = ({ errors, touched, formValues, handleChange, handleBlur }) =>
  <>
    <InputField
      required
      placeholder="www.website.com"
      label="Website"
      type="text"
      name="website"
      value={formValues.website}
      error={errors.website}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />

    <InputField
      placeholder="www.website.com/donate"
      label="Donation URL"
      type="text"
      name="donate"
      value={formValues.donate}
      error={errors.donate}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />

    <InputField
      placeholder="www.website.com/gift-card"
      label="Gift Card URL"
      type="text"
      name="gift_card"
      value={formValues.gift_card}
      error={errors.gift_card}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />

    <InputField
      placeholder="www.facebook.com/yourusername"
      label="Facebook URL"
      type="text"
      name="facebook"
      value={formValues.facebook}
      error={errors.facebook}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />

    <InputField
      placeholder="www.twitter.com/yourusername"
      label="Twitter URL"
      type="text"
      name="twitter"
      value={formValues.twitter}
      error={errors.twitter}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />

    <InputField
      placeholder="www.instagram.com/yourusername"
      label="Instagram URL"
      type="text"
      name="instagram"
      value={formValues.instagram}
      error={errors.instagram}
      touched={touched}
      handleBlur={handleBlur}
      handleChange={handleChange} />
  </>

export default InternetFields;