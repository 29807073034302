import React from 'react'
import InputField from './InputField';
import TextareaField from './TextareaField';

const CommonFields = ({ errors, touched, formValues, handleChange, handleBlur }) => {
  return (
    <>
      <InputField
        required
        placeholder="john.wellington@gmail.com"
        label="Email"
        type="email"
        name="email"
        value={formValues.email}
        error={errors.email}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />

      <InputField
        placeholder="Tags"
        label="Tags"
        type="text"
        name="tags"
        value={formValues.tags}
        error={errors.tags}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />
      <TextareaField
        placeholder="Describe your business/your work and how people can support you."
        label="Description"
        type="text"
        name="desc"
        value={formValues.desc}
        error={errors.desc}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />
    </>
  )
}

export default CommonFields;