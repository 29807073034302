import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArtistImage from "./images/artist-1.svg";

const SectionStyled = styled.div`
  background: #F7F9FF;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: #000000;
    margin-bottom: 24px;
  }
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    letter-spacing: -1.5px;
    text-align: center;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const Artists = () =>
  <div className="container mb-5">
    <SectionStyled>
      <h3 className="mb-5 text-center">Artists and creators need your help too.</h3>
      <div className="row">
        <div className="col-lg-6 mb-5">
          <img className="img-fluid d-flex mx-auto" src={ArtistImage} alt="Generic placeholder image" />
        </div>
        <div className="col-lg-6">
          <h5>Support artists and creators</h5>
          <p>Artists, creators, and makers need your help too. With events, markets and public gatherings cancelled, many creatives and freelancers have lost their income. Use the directory to easily browse artists, performers, creators, and other makers who could use your help, in cities in Canada. Purchase artwork, make donations, hire a performer and more.</p>
          <div className="col-sm-6 p-0">
            <Link className="btn btn-outline-primary btn-block" to="/artists" role="button">Browse Artists</Link>
          </div>
        </div>
      </div>
    </SectionStyled>
  </div>

export default Artists;