import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import Main from './components/Main';
import Thankyou from './components/Thankyou';
import Header from './components/Header';
import Footer from './components/Footer';

import Cities from './components/city/Cities';
import City from './components/city/City';
import Artists from './components/artist/Artists';

import FormWizard from './components/form/FormWizard';
import AddArtist from './components/form/AddArtist';
import AddLocal from './components/form/AddLocal';
import AddClass from './components/form/AddClass';

import About from './components/marketing/About';
import Privacy from './components/marketing/Privacy';
import Tos from './components/marketing/Tos';

import NotFound from './components/utilities/NotFound';
import OpenGraph from './components/utilities/OpenGraph';

import "./css/styles.css";
import "./css/geoCoder.css";
import "./css/form.css";
import "./css/marker.css";

const App = () => {
  return (
    <>
      <OpenGraph/>
      <Router>
        <div>
          <Switch>
            <Route path="/" exact>
              <Main />
            </Route>
            <Route path="/about" exact>
              <About />
            </Route>
            <Route path="/privacy" exact>
              <Privacy />
            </Route>
            <Route path="/tos" exact>
              <Tos />
            </Route>
            <Route path="/cities">
              <Cities />
            </Route>
            <Route path="/add" exact>
              <FormWizard />
            </Route>
            <Route path="/add/business">
              <>
                <Header />
                <AddLocal />
                <Footer />
              </>
            </Route>
            <Route path="/add/class">
              <AddClass />
            </Route>
            <Route path="/add/artist">
              <>
                <Header />
                <AddArtist />
                <Footer />
              </>
            </Route>
            <Route path="/artists">
              <Artists />
            </Route>
            <Route path="/city/:slug">
              <City />
            </Route>
            <Route path="/thank-you">
              <Thankyou />
            </Route>
            <Route component={NotFound} />

          </Switch>
        </div>
      </Router>
    </>
  )
}

export default App;
