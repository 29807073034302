import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../styled/Media';
import CharityImage from "./images/charity-1.svg";

const SectionStyled = styled.div`
  background: #FCF7FF;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 70px;
    color: #000000;
    margin-bottom: 24px;
  }
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #000000;
    letter-spacing: -1.5px;
    text-align: center;
  }
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
  .hideOnMobile {
    display: flex!important;
    ${media.handheld`
      display: none!important;
    `}
  }
  .showOnMobile {
    display: none!important;
    ${media.handheld`
      display: flex!important;
    `}
  }
`

const Charity = () =>
  <div className="container mb-5">
    <SectionStyled>

      <h3 className="mb-5 text-center">Help Charities and Non-Profits</h3>
      <div className="row">
        <div className="col-lg-6">
          <img className="showOnMobile img-fluid d-flex mx-auto mb-5" src={CharityImage} alt="Generic placeholder image" />
          <h5>Helping those in need</h5>
          <p>Charities and non-profit organizations need your help too. Use the directory to easily browse charities or non-profits who support some of Canada’s most vulnerable. Donate to these important organizations today.</p>
          <div className="col-sm-8 p-0">
            <Link className="btn btn-outline-primary btn-block" to="/cities" role="button">Browse Charities/Non-Profits</Link>
          </div>
        </div>
        <div className="hideOnMobile col-lg-6 my-3">
          <img className="img-fluid d-flex mx-auto" src={CharityImage} alt="Generic placeholder image" />
        </div>
      </div>
    </SectionStyled>
  </div>

export default Charity;