import React from 'react';
import styled from 'styled-components';
import { FiExternalLink } from 'react-icons/fi';

import { media } from '../styled/Media';

const Header = styled.header`
  padding: 16px;
  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 250px;
    align-items: center;
    margin: 0 auto;
    ${media.handheld`
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-row-gap: 24px;
    `}
   
  }

`

const Banner = () => {
  return (
    <Header className="bg-warning">
      <div className="container p-0">
        <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
          <div>
            <p className="font-weight-bold mb-0">ClassCast is now live.</p>
            <p className="mb-0"><small>Post and share a professional listing of your next virtual class from the comfort of your own home/gym/studio.</small></p>
          </div>
        </div>
        <a
          role="button"
          className="btn btn-dark m-0 d-flex flex-row align-items-center justify-content-center"
          href={`https://classcast.app/?ref=backinglocals`}
          target="_blank"
          rel="noopener">Go to Website<FiExternalLink size="1em" className="text-light ml-3" /> </a>
      </div>
    </Header>
  )
}

export default Banner;