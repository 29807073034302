import React from 'react'

import "../../css/checkbox.css";

const FilterForm = ({ city, checkedItems, handleInputChange, handleSubmit }) => {
  return (

    <form onSubmit={handleSubmit}>
      {checkedItems.map((item, index) => (
        <div className="form-group mb-1" key={index}>
          <label className="checkbox-label">
            <Checkbox
              name={item.name}
              checked={item.isChecked}
              onChange={handleInputChange} />
              <span className="checkbox-custom rectangular"></span>
            {item.name}
            {getTotal(city, item)}
            </label>
            
        </div>
      ))}
    </form>

  )
}

const getTotal = (city, item) => {
  let total = 0
  city.reduce((acc, current) => {
    if (current.fields.category === item.name) {
      total = total + 1;
    }
  }, 0);
  return <span className={`ml-2 badge badge-${total > 0 ? "success" : "primary"}`}>{total}</span>;
}

const Checkbox = ({ name, checked, onChange }) =>
  <input
    type="checkbox"
    name={name}
    checked={checked}
    onChange={onChange}
    className="mr-2" />

export default FilterForm;
