import React from 'react'
import InputField from './InputField';
import SelectField from './SelectField';

const LocationFields = ({ disabled, errors, touched, formValues, handleChange, handleBlur }) => {
  return (
    <>
      <InputField
        required
        disabled={disabled}
        placeholder="Tom's Hardware"
        label="Name"
        type="text"
        name="name"
        value={formValues.name}
        error={errors.name}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />

      <InputField
        required
        disabled={disabled}
        placeholder="122 Walter Hardwick Ave 305"
        label="Address"
        type="text"
        name="address"
        value={formValues.address}
        error={errors.address}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />

      <InputField
        required
        disabled={disabled}
        placeholder="Vancouver"
        label="City"
        type="text"
        name="city"
        value={formValues.city}
        error={errors.city}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />

      <SelectField
        required
        options={[
          'Alberta',
          'British Columbia',
          'Manitoba',
          'New Brunswick',
          'Newfoundland and Labrador',
          'Northwest Territories',
          'Nova Scotia',
          'Nunavut',
          'Ontario',
          'Prince Edward Island',
          'Quebec',
          'Saskatchewan',
          'Yukon Territory'
        ]}
        label="Province"
        name="province"
        value={formValues.province}
        error={errors.province}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange} />
    </>
  )
}

export default LocationFields;