import React from 'react'
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import Artists from './Artists';
import Banner from './Banner';
import Charity from './Charity';
import Header from '../Header';

const Page = () => {
  return (
    <>
      <Header />
      <Banner/>
      <Hero/>
      <HowItWorks/>
      <Artists/>
      <Charity/>
    </>
  )
}

export default Page;