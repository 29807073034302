import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../styled/Media';

import SiblingImage from "./images/siblings-1.svg";

const SectionStyled = styled.div`
  background: #FFFDF5;
  border-radius: 16px;
  padding: 50px;
  margin-bottom: 50px;
  ${media.handheld`
    width: 100%;
    padding: 32px;
  `}
  h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    letter-spacing: -1px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #4D5255;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 30px;
  }
`

const CTA = () =>
  <div className="container mb-5">
    <SectionStyled>
      <div className="d-flex flex-row flex-wrap align-items-center">
        <div className="col-lg-6">
          <img className="img-fluid d-flex my-5 mx-auto" src={SiblingImage} alt="Generic placeholder image" />
        </div>
        <div className="col-lg-6">
          <h5>Support your community</h5>
          <p>Buy gift cards online now to use later, order products or services online or donate to help ensure your local community makes it through the pandemic.</p>
          <div className="col-sm-6 p-0">
            <Link className="btn btn-outline-primary btn-block" to="/cities" role="button">Browse Businesses/Charities</Link>
          </div>
        </div>
      </div>
    </SectionStyled>
  </div>

export default CTA;