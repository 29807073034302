import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { FiChevronUp, FiPlus } from 'react-icons/fi';

import Header from '../Header';
import Footer from '../Footer';
import Alphabet from './Alphabet';
import LoadingSpinner from '../utilities/LoadingSpinner';

const HeaderStyled = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 36px;
  color: #000000;
  letter-spacing: -1.5px;
  text-align: center;
`

const Artists = () => {
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(null);

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        let res = await fetch(`${process.env.NODE_ENV === 'production' ? `https://salty-basin-83556.herokuapp.com/api/artist/all` : '/api/artist/all'}`);
        let json = await res.json();
        setArtists(json);
      } catch (err) {
        throw err;
      }
      setIsLoading(false);
    };
    runEffect();
  }, []);

  const toggleClass = (clicked) => {
    setActive(active === clicked.id ? null : clicked.id)
  }

  const moreLess = (clicked) => {
    if (active === clicked) { return <FiChevronUp size="24px" color="#385075" /> }
    else { return <FiPlus size="24px" color="#385075" /> }
  }

  if (artists.length === 0 || isLoading) return <LoadingSpinner />
  return (
    <>
      <Header className="mb-5" />
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="col-lg-12 col-md-10 col-sm-10 my-5 mx-0 text-center">

            <HeaderStyled>Support Artists, Creators and Makers in Canada</HeaderStyled>
            <p>With events, markets and public gatherings cancelled, many artists, creators and makers have lost their income. Browse and purchase artwork, products, make donations, hire a performer and more.</p>
          </div>
        </div>
        <Alphabet
          data={artists}
          active={active}
          moreLess={moreLess}
          toggleClass={toggleClass} />
      </div>
      <Footer />
    </>
  )
}

export default Artists;