import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';

import LoadingSpinner from '../utilities/LoadingSpinner';

import SelectField from './fields/SelectField';
import InputField from './fields/InputField';
import CommonFields from './fields/CommonFields';
import InternetFields from './fields/InternetFields';

import validateArtistFields from './validation/validateArtistFields';
import validateForm from './validation/validateForm';

import { media } from '../styled/Media';
import { toTitleCase } from '../../helpers';

const PageStyled = styled.div`
  margin: 30px auto 100px auto;
  width: 720px;
  ${media.handheld`
    width: 100%;
    padding: 0 24px;
  `}

  h1 {
    margin-bottom: 24px;
    font-family: 'Open Sans', sans-serif;
    font-size: 54px;
    line-height: 60px;
    color: #000000;
    letter-spacing: -3px;
    text-align: center;
  ${media.handheld`
    font-size: 40px;
    line-height: 50px;
  `}
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: #4D5255;
    letter-spacing: 0;
    text-align: center;
  }
`

const AddArtist = () => {
  const [formValues, setFormValues] = useState({
    type: "Artist"
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState([])
  const [fieldsIsValid, setFieldsIsValid] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);
  let history = useHistory();
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (formValues !== null) {
      const validatedFields = validateArtistFields(formValues);
      const touchedErrors = Object.keys(validatedFields.errors)
        .filter(key => touched.includes(key)) // get all touched keys - expected output: ["name", "email", "age", "gender"]
        .reduce((acc, key) => {
          if (!acc[key]) {
            acc[key] = validatedFields.errors[key]
          }
          return acc;
        }, {});
      setErrors(touchedErrors);
  
      setFieldsIsValid(validatedFields.fieldsIsValid);
    }
  }, [touched, formValues]);

  React.useEffect(() => {
    console.log('fieldsIsValid', fieldsIsValid)
    const isValid = validateForm(fieldsIsValid);
    setFormIsValid(isValid);
  }, [fieldsIsValid]);

  const handleBlur = (e) => {
    // if touched hasn't been touched before add it to the array
    if (!touched.includes(e.target.name)) {
      setTouched([
        ...touched,
        e.target.name
      ])
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormValues((prevState) => {
      return {
        ...prevState,
        [name]: value
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitting');
    formValues.type = toTitleCase(location.pathname.split("/")[2]);
    setIsLoading(true);

    await fetch(`${process.env.NODE_ENV === 'production' ? `https://salty-basin-83556.herokuapp.com/api/artist/add` : `/api/artist/add`}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formValues),
    });

    setIsLoading(false);
    history.push("/thank-you");
  }

  if (isLoading) return <LoadingSpinner />
  return (
    <div style={{ background: "#F9F9F9" }}>
      <div className="container py-5">
        <PageStyled className="col-sm-6 text-center">
          <h1>Add an artist, creator or maker in your city.</h1>
          <p>If you are an artist, freelancer, creator or maker with a website where people can support you online, fill out the form below.</p>
        </PageStyled>
        <div className="card col-sm-6 p-0 mx-auto">
          <form onSubmit={handleSubmit} className="form">
            <div className="card-body mb-5">

              <InputField
                required
                placeholder="Tom's Hardware"
                label="Name"
                type="text"
                name="name"
                value={formValues.name}
                error={errors.name}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <InputField
                required
                placeholder="Vancouver"
                label="City"
                type="text"
                name="city"
                value={formValues.city}
                error={errors.city}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <SelectField
                required
                options={[
                  'Alberta',
                  'British Columbia',
                  'Manitoba',
                  'New Brunswick',
                  'Newfoundland and Labrador',
                  'Northwest Territories',
                  'Nova Scotia',
                  'Nunavut',
                  'Ontario',
                  'Prince Edward Island',
                  'Quebec',
                  'Saskatchewan',
                  'Yukon Territory'
                ]}
                label="Province"
                name="province"
                value={formValues.province}
                error={errors.province}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <CommonFields
                errors={errors}
                touched={touched}
                formValues={formValues}
                handleBlur={handleBlur}
                handleChange={handleChange} />

              <InternetFields
                errors={errors}
                touched={touched}
                formValues={formValues}
                handleBlur={handleBlur}
                handleChange={handleChange} />

            </div>
            <div className="card-footer">
              <div className="d-flex flex-wrap justify-content-between">
                <button className="btn btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                <button className={`btn ${!formIsValid ? "disabled btn-secondary" : "btn-primary"}`} tabIndex="-1" role="button" aria-disabled="true" disabled={!formIsValid}>Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddArtist;