import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { FiChevronUp, FiPlus } from 'react-icons/fi';

import { useParams } from "react-router-dom";
import { toTitleCase } from '../../helpers';


import Header from '../Header';
import { media } from '../styled/Media';

import FilterForm from '../filter/FilterForm';
import SearchableMap from '../utilities/SearchableMap';
import LoadingSpinner from '../utilities/LoadingSpinner';
import ProfileCard from '../utilities/ProfileCard';

let categoryList = [
  { name: "Arts and Entertainment", isChecked: false },
  { name: "Beer and Liquor", isChecked: false },
  { name: "Bicycle Repair", isChecked: false },
  { name: "Business Services", isChecked: false },
  { name: "Charities and Nonprofit", isChecked: false },
  { name: "Commercial Services", isChecked: false },
  { name: "Communications", isChecked: false },
  { name: "Construction and Renovation", isChecked: false },
  { name: "Design and Creative", isChecked: false },
  { name: "Education", isChecked: false },
  { name: "Finance and Accounting", isChecked: false },
  { name: "Grocery", isChecked: false },
  { name: "Health and Wellness", isChecked: false },
  { name: "Health and Beauty", isChecked: false },
  { name: "Home Repair and Maintenance", isChecked: false },
  { name: "Hospitality", isChecked: false },
  { name: "Insurance", isChecked: false },
  { name: "Pets", isChecked: false },
  { name: "Real Estate", isChecked: false },
  { name: "Restaurant and Food", isChecked: false },
  { name: "Retail", isChecked: false },
  { name: "Rideshare, Taxi and Transportation", isChecked: false },
  { name: "Sports and Fitness", isChecked: false },
  { name: "Support Services", isChecked: false },
  { name: "Technology", isChecked: false },
  { name: "Toys and Hobbies", isChecked: false },
  { name: "Vehicle Maintenance and Repair", isChecked: false },
  { name: "Other", isChecked: false }
]

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 3fr 500px;
  height: 100vh;
  ${media.handheld`
    grid-template-columns: 1fr;
  `}

  .filter {
    display: block;
    ${media.handheld`
      display: none;
    `}
  }
  .map {
    ${media.handheld`
      width: 100%;
      height: 250px;
    `}
  }
  .listing {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    overflow-y: scroll;
    ${media.handheld`
      overflow-y: visible;
    `}
  }
`


const City = () => {
  const [initLocalList, setInitLocalList] = useState([]);
  const [city, setCity] = useState({});
  const [selected, setSelected] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const [checkedItems, setCheckedItems] = useState(categoryList);

  const [searchResults, setSearchResults] = useState([]);
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let { slug } = useParams();

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {

        const fetchAll = () => {
          return fetch(`${process.env.NODE_ENV === "production" ? `https://salty-basin-83556.herokuapp.com/api/city/all/${slug}` : `/api/city/all/${slug}`}`);
        }

        const fetchCity = () => {
          return fetch(`${process.env.NODE_ENV === "production" ? `https://salty-basin-83556.herokuapp.com/api/city/details/${slug}` : `/api/city/details/${slug}`}`);
        }

        // const fetchNextPage = () => {
        //   return fetch(`${process.env.NODE_ENV === "production" ? `https://salty-basin-83556.herokuapp.com/api/city/select/${slug}/${page}` : `/api/city/select/${slug}/${page}`}`);
        // }

        const [result1, result2] = await Promise.all([fetchAll(), fetchCity()]);

        let json = await result1.json();
        let json2 = await result2.json();
        // Hack
        const limit = 8;
        const offset = (page * limit) - limit;
        const jsonCopy = [...json];
        let results = jsonCopy.slice(offset, limit * page);

        setInitLocalList(json);
        setSearchResults(results);
        setCity(json2[0].fields);
      } catch (err) {
        throw err;
      }
      setIsLoading(false);
    };
    runEffect();
  }, []);

  useEffect(() => {
    const runEffect = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${process.env.NODE_ENV === "production" ? `https://salty-basin-83556.herokuapp.com/api/city/select/${slug}/${page}` : `/api/city/select/${slug}/${page}`}`);
        let json = await res.json();
        setPageMax(json.pages);
        setSearchResults(json.results);
      } catch (err) {
        throw err;
      }
      setIsLoading(false);
      window.scrollTo(0, 0);
    };
    runEffect();
  }, [page]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const results = initLocalList.filter(person =>
        person.fields.name.toLowerCase().includes(searchTerm)
      );
      setSearchResults(results);
    } else {
      setSearchResults(initLocalList);
    }

  }, [searchTerm]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  }

  const handleOnChange = (e) => {
    const name = e.target.name;
    let checkedItemsCopy = [...checkedItems];

    checkedItemsCopy.find(category => category.name === name).isChecked = e.target.checked;
    setCheckedItems(checkedItemsCopy);

    let shouldReset = checkedItems.every(x => !x.isChecked);

    if (shouldReset) {
      const results = initLocalList.filter(business =>
        business.fields.name.toLowerCase().includes(searchTerm)
      );

      setSearchResults(results);

    } else {
      let initLocalListCopy = [...initLocalList];
      let filteredList = initLocalListCopy.reduce(function (r, e) {
        let f = checkedItemsCopy.find(el => e.fields.category === el.name && el.isChecked)
        r.push(f && e);
        return r;
      }, []).filter(el => el != null);

      const result = filteredList.filter(business =>
        business.fields.name.toLowerCase().includes(searchTerm)
      );

      setSearchResults(result);
    }
  };

  const previousPage = () => {
    setPage(prevPage => {
      if (prevPage === 1) {
        return prevPage = 1;
      } else {
        return prevPage - 1;
      }
    });
  }

  const nextPage = async () => {
    setPage(prevPage => {
      if (prevPage === pageMax) {
        return prevPage;
      } else {
        return prevPage + 1;
      }
    });
  }

  const toggleClass = (local) => {
    setSelected(local.fields);
    setActive(active === local.id ? null : local.id)
  }

  const moreLess = (clicked) => {
    if (active === clicked) { return <FiChevronUp size="24px" /> }
    else { return <FiPlus size="24px" /> }
  }

  if (initLocalList.length === 0) return <LoadingSpinner />
  return (
    <>
      <Header />
      <Layout>
        <div className="filter p-3">
          <div className="mb-4 pb-4">
            <h5 className="m-0">Category</h5>
          </div>
          <FilterForm checkedItems={checkedItems} city={initLocalList} handleInputChange={handleOnChange} />
        </div>
        <div className="map">
          {searchResults.length > 0 ? <SearchableMap city={initLocalList} selected={selected} /> : <div className="d-flex align-items-center justify-content-center"><h5>No listing found.</h5></div>}
        </div>

        <div className="listing border-left box-shadow p-3">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="col-sm-12 pl-0 mb-2">
              <h5 className="m-0">Local Listings in {toTitleCase(slug)}</h5>
              <p className="m-0">{city.business_total} local listings from {Object.keys(city).length > 0 ? `${slug}, ${city.province}.` : "searching..."}</p>
              
            </div>
            <div className="col-sm-12 p-0 mb-4">
              <form className="form">
                <label>Search listings by name</label>
                <input
                  type="text"
                  placeholder="Search business by name"
                  className="w-100"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </form>
            </div>
          </div>

          <div className="d-flex flex-column">
            {isLoading ? <LoadingSpinner /> : (searchResults || []).map(item => <ProfileCard key={item.id} data={item} active={active} toggleClass={toggleClass} moreLess={moreLess} />)}
          </div>

          <div className="d-flex align-items-center justify-content-center btn-group mb-3" role="group" aria-label="Pagination">
            <button type="button" className="btn btn-light" disabled={page === 1 || !checkedItems.every(x => !x.isChecked)} onClick={previousPage}>Back</button>
            <button type="button" className="btn btn-light" disabled={true} style={{ background: "white" }}>Page {!checkedItems.every(x => !x.isChecked) ? "1" : page} of {!checkedItems.every(x => !x.isChecked) ? "1" : pageMax}</button>
            <button type="button" className="btn btn-light" disabled={page === pageMax || !checkedItems.every(x => !x.isChecked)} onClick={nextPage}>Next</button>
          </div>

          <div className="text-center">
            <p><small className="text-muted">If there is an error in a listing, email <a href="mailto:backinglocals@gmail.com?subject=inquiry">backinglocals@gmail.com</a> to request an update.</small></p>
          </div>

        </div>
      </Layout>
    </>
  )
}

export default City;