import React from 'react';
import { IoLogoFacebook, IoLogoTwitter, IoLogoInstagram, IoMdMail } from 'react-icons/io';
import styled from 'styled-components';
import isEmpty from '../form/validation/isEmpty';
import { Link } from 'react-router-dom';

const CardStyled = styled.div`
  display: flex;
  flex-direction:column;
  margin-bottom: 16px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #d2d9e5;
  box-shadow: 0 1px 0 0 rgba(5,12,38,0.1);
  border-radius: 6px;
  h3 {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4D5255;
  }
  p {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #385075;
    letter-spacing: 0;
    line-height: 24px;
  }
`

const ProfileCard = ({ data, active, toggleClass, moreLess }) =>
  <CardStyled>
    <div className="d-flex align-items-center justify-content-between" onClick={() => toggleClass(data)} style={{ cursor: "pointer" }}>
      <div>
        <h3 className="mb-0">{data.fields.name}</h3>
        <p className="mb-0">{data.fields.address} <span className="badge badge-warning mr-2">{data.fields.category}</span></p>
      </div>
      {moreLess(data.id)}
    </div>
    {active === data.id &&
      <>
        <div>
          {data.fields.address &&
            <p className="mt-2">
              <span className="font-weight-bold">Address: </span>
              {data.fields.address}
            </p>
          }
          {data.fields.phone_number && <p className="m-0">
            <span className="font-weight-bold">Phone Number: </span>
            <a href={`tel:${data.fields.phone_number}`}>{data.fields.phone_number}</a>
          </p>
          }
          <div className="mt-2 mb-3">
            {data.fields.email &&
              <a
                role="button"
                className="btn btn-light btn-sm mt-0 mr-1"
                href={`mailto:${data.fields.email}?subject=Referred by BackingLocals.com`}><IoMdMail size="1em" /></a>}
            {data.fields.facebook &&
              <a
                role="button"
                className="btn btn-primary btn-sm mt-0 mx-1"
                href={data.fields.facebook.match(/^(http|https):/g) ? `${data.fields.facebook}?ref=backinglocals` : `http://${data.fields.facebook}`}
                target="_blank"
                rel="noopener"><IoLogoFacebook size="1em" className="text-light" /></a>}
            {data.fields.twitter && <a
              role="button"
              style={{ backgroundColor: "#08a0e9" }}
              className="btn btn-sm mt-0 mx-1"
              href={data.fields.twitter.match(/^(http|https):/g) ? `${data.fields.twitter}?ref=backinglocals` : `http://${data.fields.twitter}`}
              target="_blank"
              rel="noopener"><IoLogoTwitter size="1em" className="text-light" /></a>}
            {data.fields.instagram && <a
              role="button"
              style={{ backgroundColor: "#C13584" }}
              className="btn btn-sm mt-0 mx-1"
              href={data.fields.instagram.match(/^(http|https):/g) ? `${data.fields.instagram}?ref=backinglocals` : `http://${data.fields.instagram}`}
              target="_blank"
              rel="noopener"><IoLogoInstagram size="1em" className="text-light" /></a>}
          </div>
        </div>

        {!isEmpty(data.fields.tags) && <div className="d-flex flex-wrap">
          {data.fields.tags.split(',').map((tag, index) => <span key={index} className="badge badge-info my-2 mr-2">{tag}</span>)}
        </div>}

        {data.fields.desc && <p className="mt-3">{data.fields.desc}</p>}

        <div className="d-flex pt-3">
          {data.fields.gift_card &&
            <a
              role="button"
              className="btn btn-outline-primary d-block w-100 mr-2"
              href={data.fields.gift_card.match(/^(http|https):/g) ? `${data.fields.gift_card}?ref=backinglocals` : `http://${data.fields.gift_card}?ref=backinglocals`}
              target="_blank"
              rel="noopener">Buy a Gift Card</a>
          }
          {data.fields.donate &&
            <a
              role="button"
              className="btn btn-outline-primary d-block w-100 mr-2"
              href={data.fields.donate.match(/^(http|https):/g) ? `${data.fields.donate}?ref=backinglocals` : `http://${data.fields.donate}?ref=backinglocals`}
              target="_blank"
              rel="noopener">Make a donation</a>
          }
          <a
            role="button"
            className="btn btn-outline-primary d-block w-100 mr-2"
            href={data.fields.website.match(/^(http|https):/g) ? `${data.fields.website}?ref=backinglocals` : `http://${data.fields.website}?ref=backinglocals`}
            target="_blank"
            rel="noopener">Go to Website</a>
        </div>
      </>
    }
  </CardStyled>

export default ProfileCard;